// @flow

import React, { useState, type Element } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  title: string,
  children: Element<any>,
  wrapperCustomClass?: string,
};

const Collapsible = ({ title, children, wrapperCustomClass = '' }: Props) => {
  const [open, setOpen] = useState(false);

  const handleOnClick = () => setOpen((currentState) => !currentState);

  return (
    <List className={styles.list}>
      <ListItem className={styles.title} button onClick={handleOnClick}>
        <ListItemText primary={title} />
        <div className={styles.arrow}>{open ? <ExpandLess /> : <ExpandMore />}</div>
      </ListItem>
      <Collapse in={open} timeout="auto" classes={{ wrapper: classNames(styles.collapseWrapper, wrapperCustomClass) }}>
        {children}
      </Collapse>
    </List>
  );
};

export default Collapsible;
