import * as Sentry from '@sentry/react';
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { persistCache } from 'apollo-cache-persist';
import { onError } from 'apollo-link-error';
import { getStore } from './configureStore';
import { signOutSuccess } from './containers/SignIn/actions';
import { authTokenSelector, selectedRootEntitiesSelector } from './containers/SignIn/selectors';
import errorFactory from './utils/errorFactory';
import { lastActivitySet } from './utils/activity';
import { addError } from './containers/GlobalMessage/actions';

class ApolloApiCallError extends Error {
  constructor(name, message, info) {
    super();
    this.name = name;
    this.message = name;
    this.info = info;
  }
}

export default function configureApollo(client = 'users') {
  const store = getStore();
  const isUserClient = client === 'users';

  // setup cache and rehydrate
  const cache = new InMemoryCache();
  persistCache({ cache, storage: window.sessionStorage });

  const graphqlClients = {
    users: process.env.REACT_APP_API_CLIENT_URL,
    patients: process.env.REACT_APP_API_PATIENTS_CLIENT_URL,
  };

  const link = new HttpLink({
    uri: graphqlClients[client],
  });

  // setup auth middle ware for apollo to pull auth token out of sessionStorage
  // for authentication
  const authMiddleware = new ApolloLink((operation, forward) => {
    const headers = {};
    const token = authTokenSelector(store.getState());
    const selectedRootEntities = selectedRootEntitiesSelector(store.getState());

    const refreshBlacklist = ['refreshToken', 'GetChatAlerts', 'GetUserReports'];
    const isBlacklistedOperation = refreshBlacklist.includes(operation.operationName);

    const context = operation.getContext();

    if (isUserClient) {
      headers['root-entities'] = selectedRootEntities;
    }

    if (context.phone) {
      headers.phone = context.phone;
    }

    if (context.resetToken) {
      headers['reset-token'] = context.resetToken;
    }

    if (context.patientUuid) {
      headers['patient-uuid'] = context.patientUuid;
    }

    if (token && isUserClient) {
      headers.authorization = `Bearer ${token}`;

      if (!isBlacklistedOperation) {
        lastActivitySet();
      }
    }

    operation.setContext({ headers });

    return forward(operation);
  });

  const errorMiddleware = onError((apolloLinkError) => {
    const errors = errorFactory(apolloLinkError);

    errors.forEach(({ status, message = {}, error = {} } = {}) => {
      store.dispatch(addError(message, error));

      const sentryError = (error && error.path) || (message && message.id) || 'Error occurred';

      Sentry.captureException(new ApolloApiCallError(sentryError, sentryError, apolloLinkError), {
        extra: {
          displayed: message.id || 'API Error',
          apolloError: true,
          ...error,
        },
      });

      if (status === 401 && isUserClient) {
        store.dispatch(signOutSuccess());
      }
    });
  });

  // ApolloClient
  return new ApolloClient({
    link: ApolloLink.from([authMiddleware, errorMiddleware, link]),
    cache,
    connectToDevTools: true,
    dataIdFromObject: (object) => object.id,
  });
}
