// @flow

/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import type { Store as ReduxStore, Dispatch as ReduxDispatch } from 'redux';
import { ApolloProvider } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import SecureRoute from '../../components/SecureRoute';
import NotFoundPage from '../NotFoundPage/Loadable';
import WebUsers from '../WebUsers/Loadable';
import AllPatients from '../AllPatients/Loadable';
import ForgotPassword from '../ForgotPassword/Loadable';
import PasswordReset from '../PasswordReset/Loadable';
import PatientPasswordReset from '../PatientPasswordReset/Loadable';
import RegimenTemplates from '../RegimenTemplate/Loadable';
import NotificationLogs from '../NotificationLogs/Loadable';
import AuditLogs from '../SecurityLogs/Loadable';
import GlobalMessage from '../GlobalMessage';
import RootRedirect from '../../components/RootRedirect';
import Search from '../Search/Loadable';
import Alerts from '../Alerts/Loadable';
import Analytics from '../Analytics/Loadable';
import DownloadEmochaApp from '../../components/DownloadEmochaApp';
import PatientOnboarding from '../PatientOnboarding/Loadable';
import DownloadAppWebpage from '../DownloadAppWebpage/Loadable';
import PatientOnboardingSuccess from '../PatientOnboardingSuccess/Loadable';
import OptInCampaign from '../OptInCampaign/Loadable';
import ExternalCellPhoneVerification from '../ExternalPatientOnBoarding/ExternalCellPhoneVerification/Loadable';
import ExternalTokenVerification from '../ExternalPatientOnBoarding/ExternalTokenPhoneVerification/Loadable';
import ExternalTermsOfUse from '../ExternalPatientOnBoarding/ExternalTermsOfUse/Loadable';
import { addClass } from '../../utils/styles';
import { isIE11Win7 } from '../../utils/browser';
import withHandlers from './withHandlers';
import { setSubmissionDetailsAction } from '../PatientSubmissions/actions';
import configureApollo from '../../configureApollo';
import LiveVideo from '../LiveVideo';
import SupportCase from '../SupportCase/Loadable';
import CognitoSignIn from '../SignIn/Cognito/Loadable';
import QuickEnroll from '../QuickEnroll/Loadable';
import NoPermissionsLanding from '../NoPermissionsLanding/Loadable';
import ContentManagement from '../ContentManagement/Loadable';
import RuleEngine from '../RuleEngine/Loadable';
import Patient from '../Patient/Loadable';
import PlanEligiblePatients from '../PlanEligiblePatients/Loadable';
import PayItForward from '../PayItForward/Loadable';
import { authUserSelector } from '../SignIn/selectors';
import PatientSelfEnrollment from '../PatientSelfEnrollment';
import Payer from '../Payer';
import MyProfile from '../Profile';
import { MyProfileProvider } from '../../hooks/useMyProfile';

type Props = {
  history: {
    goBack: () => void,
    push: (string) => void,
  },
  location: { hash: string },
  handleOnSelect: (uuid: string) => void,
};

export class App extends React.Component<Props> {
  componentDidMount = () => {
    if (isIE11Win7()) {
      addClass(document.getElementsByTagName('html')[0], 'isIE11Win7');
    }
  };

  isSearch = (hash: string) => hash === '#patient-search';

  loginRedirect = () => <Redirect to="/login" />;

  render() {
    const {
      location: { hash },
      history: { goBack },
      handleOnSelect,
    } = this.props;
    const isSearch = this.isSearch(hash);
    return (
      <div>
        <CssBaseline />
        <GlobalMessage />
        <MyProfileProvider>
          <Switch>
            <Route path="/" component={RootRedirect} exact />
            <SecureRoute path="/analytics/data/:dashboard" permission="analytics" component={Analytics} />
            <SecureRoute path="/analytics" permission="analytics" component={Analytics} />
            <SecureRoute
              path="/analytics/incentives/ledger/:patientUuid?"
              permission="analytics"
              component={Analytics}
            />
            <SecureRoute path="/analytics/overrides" permission="analytics" component={Analytics} />
            <SecureRoute path="/content-management" permission="contentManagement" component={ContentManagement} />
            <SecureRoute path="/alerts" permission="submissions" component={Alerts} />
            <SecureRoute path="/web-users" permission="users" component={WebUsers} />
            <SecureRoute path="/regimen-templates" permission="manageRegimenTemplate" component={RegimenTemplates} />
            <SecureRoute path="/notification-logs" permission="notifications" component={NotificationLogs} />
            <SecureRoute path="/patients" permission="patients" component={AllPatients} />
            <SecureRoute path="/rule-engine" permission="ruleEngine" component={RuleEngine} />
            <SecureRoute path="/patient/:uuid" permission="patient" component={Patient} />
            <SecureRoute path="/security-logs" permission="auditLog" component={AuditLogs} />
            <SecureRoute path="/quick-enroll" permission="quickEnrollPage" component={QuickEnroll} />
            <SecureRoute path="/eligible-patients" permission="planEligiblePatients" component={PlanEligiblePatients} />
            <SecureRoute path="/pay-it-forward" permission="managePayItForward" component={PayItForward} />
            <SecureRoute path="/no-permissions" permission="nullPermission" component={NoPermissionsLanding} />
            <Route path="/payer" component={Payer} />
          </Switch>
          <MyProfile />
        </MyProfileProvider>
        <Switch>
          <Route path="/sign-in" component={this.loginRedirect} />
          <Route path="/login" component={CognitoSignIn} />

          <Route path="/forgot-password" component={ForgotPassword} />
          {/* tobe removed after 2.0 migrations ends */}
          <Route path="/forgot-password/:new-arrive" component={ForgotPassword} />
          <Route path="/reset-password/:resetToken" component={PasswordReset} />
          <Route path="/user-welcome/:resetToken" component={PasswordReset} />
          <Route path="/download-emocha-app" component={DownloadEmochaApp} />
          <ApolloProvider client={configureApollo('patients')}>
            <Route path="/self-enrollment/:slug?" component={PatientSelfEnrollment} />
            <Route path="/support-cases/:patientUuid/:supportCaseUuid" component={SupportCase} />
            <Route path="/external-onboarding/phone" component={ExternalCellPhoneVerification} />
            <Route path="/external-onboarding/token" component={ExternalTokenVerification} />
            <Route path="/external-onboarding/terms-of-use" component={ExternalTermsOfUse} />
            <Route path="/patient-reset-password/:resetToken" component={PatientPasswordReset} />
            <Route path="/welcome-download-emocha-app/:patientUuid/:resetToken/" component={DownloadAppWebpage} />
            <Route path="/onboarding/:patientUuid/:resetToken/:phone?" component={PatientOnboarding} />
            <Route path="/onboarding-success/:patientUuid/:resetToken" component={PatientOnboardingSuccess} />
            <Route path="/opt-in/:optInUrlHash" component={OptInCampaign} />
          </ApolloProvider>
          <Route component={NotFoundPage} />
        </Switch>
        {isSearch && <Search onClose={goBack} onSelect={handleOnSelect} />}
        <LiveVideo />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore) => ({
  authUser: authUserSelector(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  setSubmissionDetails: (submissionDetails) => dispatch(setSubmissionDetailsAction(submissionDetails)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withHandlers)(App);
